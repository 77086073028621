import { reportVerbiage } from '../constants';
import { Languages } from '../enums';
import { Exercise, InOfficeVisit } from '../types';

export const createPrescription = (
  language: Languages,
  visit: InOfficeVisit,
) => {
  const timePerOrTimesPer =
    visit.times === 1
      ? reportVerbiage[language === 'es' ? 'es' : 'en'].timePer
      : reportVerbiage[language === 'es' ? 'es' : 'en'].timesPer;
  const durationUnit =
    visit.duration === 1 ? visit.durationUnit : `${visit.durationUnit}s`;
  const visitDuration =
    reportVerbiage[language === 'es' ? 'es' : 'en'][durationUnit];
  const visitTimes = language === 'es' && visit.times === 1 ? '' : visit.times;
  return `${visitTimes} ${timePerOrTimesPer} ${
    reportVerbiage[language === 'es' ? 'es' : 'en'][visit.timesUnit]
  } ${reportVerbiage[language === 'es' ? 'es' : 'en'].for} ${
    visit.duration
  } ${visitDuration}`;
};

export const prescription = (exercise: Exercise, language?: Languages) => {
  const performString = language === Languages.es ? 'Realizar' : 'Perform';
  const perString = language === Languages.es ? 'por' : 'per';
  const singularRepString = language === Languages.es ? 'repetición' : 'rep';
  const customRepUnit =
    exercise.prescriptionRepUnit && exercise.prescriptionRepUnit !== 'rep'
      ? exercise.prescriptionRepUnit
      : singularRepString;
  const pluralRepString =
    language === Languages.es && !exercise.prescriptionRepUnit
      ? 'repeticiones'
      : `${customRepUnit}s`;

  const setString = language === Languages.es ? 'conjunto' : 'set';
  const pluralSetString = language === Languages.es ? 'conjuntos' : `sets`;

  const ofString = language === Languages.es ? 'de' : 'of';

  const timesString = language === Languages.es ? 'veces' : 'times';
  const singleTime = language === Languages.es ? 'veces' : 'time';
  const timeOrTimes =
    exercise.prescriptionScheduleTimes === 1 ? singleTime : timesString;
  const reps = exercise.prescriptionReps;
  const singular = reps === 1;
  const repUnit = singular ? customRepUnit : pluralRepString;
  const schedulePerString =
    language === Languages.es &&
    (!exercise.prescriptionSchedulePer ||
      exercise.prescriptionSchedulePer === 'day')
      ? 'día'
      : exercise.prescriptionSchedulePer || 'day';
  const schedulePer = schedulePerString
    ? ` ${perString} ${schedulePerString}`
    : '';

  const scheduleTimes = exercise.prescriptionScheduleTimes;
  const sets = exercise.prescriptionSets;
  const singularSets = sets === 1;
  const setsVerbiage = singularSets ? setString : pluralSetString;

  const atLeastOneThing = sets || reps || scheduleTimes;
  const setsFinal = sets ? `${sets} ${setsVerbiage}` : '';
  const repsFinal = reps ? `${reps} ${repUnit}` : '';
  const setsAndRepsSeparator = setsFinal && repsFinal ? ` ${ofString} ` : '';
  const scheduleFinal = scheduleTimes
    ? `${scheduleTimes} ${timeOrTimes}${schedulePer}`
    : '';
  const repsAndScheduleSeparator =
    (setsFinal || repsFinal) && scheduleFinal ? ', ' : '';

  return atLeastOneThing
    ? `${performString} ${setsFinal}${setsAndRepsSeparator}${repsFinal}${repsAndScheduleSeparator}${scheduleFinal}`
    : '';
};
