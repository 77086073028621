import React from 'react';
import ReactPlayer from 'react-player';

type Props = {
  url: string;
};

const ResponsivePlayer: React.FC<Props> = ({ url }) => {
  return (
    <div className="player-wrapper rounded-lg overflow-hidden">
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        controls
        pip
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
            forceHLS: url?.includes('.m3u8'),
          },
        }}
        playing={false}
      />
    </div>
  );
};

export default ResponsivePlayer;
